import { useEffect, useState } from 'react';
import { useQuery } from './utils/hooks';

function App() {
  const query = useQuery();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (query && query.get('id')) {
      getIframeUrl(query.get('id'));
    }
  }, [query]);

  const getIframeUrl = async (organizationid = 1) => {
    fetch('http://localhost:3008/soja-reports-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ organizationid }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          var iframeUrl =
            'https://reports.soja.co.ke/embed/dashboard/' +
            data.token +
            '#bordered=false&titled=false';

          setUrl(iframeUrl);
        }
      })
      .catch((e) => console.error(e));

    // setUrl(iframeUrl);
  };

  return (
    <div style={{ display: 'relative' }}>
      {url && (
        <iframe
          style={{
            width: '100%',
            display: 'absolute',
            height: '100vh',
          }}
          title="Reports"
          src={url}
          frameBorder={0}
          allowtransparency={true}
        />
      )}
    </div>
  );
}

export default App;
